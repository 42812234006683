/** @jsx jsx */
import { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { Box, jsx } from 'theme-ui'
import ShoppingCartIcon from '~/assets/images/icons/shopping-cart.svg'

const NavMenuDesktopCartLink = ({ children, itemCount, strokeColor }) => {
  const [showItemCount, setShowItemCount] = useState(false)

  let itemCountColor = strokeColor
  if (itemCountColor === '#91D3CA') {
    itemCountColor = '#005D5F'
  }

  useEffect(() => {
    setShowItemCount(typeof itemCount === 'number')
  }, [itemCount])

  return (
    <Link
      key="cart"
      to="/cart/"
      sx={{
        color: 'inherit',
        //opacity: showItemCount ? 1 : 0,
        variant: 'text.navLink',
        marginLeft: '3rem',
        textAlign: 'center',
        position: 'relative',
        zIndex: 3,
      }}
    >
      <ShoppingCartIcon sx={{
          position: 'relative',
          top: '0.25rem',
          '& g': {
            stroke: strokeColor === '#FFFFFF' ? '#000000' : strokeColor,
          }
        }}
      />
      <Box sx={{
        background: 'white',
        width: '1.5rem',
        height: '1.5rem',
        borderRadius: '100%',
        color: itemCountColor,
        fontSize: '14px',
        position: 'absolute',
        right: '-1.15rem',
        top: '-0.75rem',
        //opacity: showItemCount ? 1 : 0
      }}>{itemCount || 0}</Box>
    </Link>
  )
}

NavMenuDesktopCartLink.propTypes = {
  itemCount: PropTypes.number,
  strokeColor: PropTypes.string
}

export default NavMenuDesktopCartLink
