/** @jsx jsx */
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { Box, Flex, Text, jsx } from 'theme-ui'
import { useTranslate } from '@arfabrands/gatsby-theme-assembly'
import FooterSocial from '~/components/Footer/Social'

const FooterLinks = ({ catalog, links }) => {
  const translate = useTranslate()

  return (
    <Flex
      sx={{
        flexWrap: 'wrap',
        textAlign: 'left',
        width: ['100%', null, '50%']
      }}
    >
      <Box
        sx={{
          flex: ['45%', '25%', '33%'],
          lineHeight: ['25px', 'inherit'],
          padding: ['0', '0 2rem 0 0'],
        }}
      >
        <Text sx={{ marginBottom: '0.5rem' }}>
          <Link to="/shop/">{translate('footer.shop.shop_all')}</Link>
        </Text>
        {catalog.collections
          .filter(({ slug }) => slug !== 'kits')
          .map(({ title, slug }) => {
            return (
              <Text sx={{ marginBottom: '0.5rem' }} key={slug}>
                <Link to={`/shop/?collection=${slug}`}>{title}</Link>
              </Text>
            )
          })}
      </Box>

      <Box
        sx={{
          flex: ['45%', '25%', '33%'],
          lineHeight: ['25px', 'inherit'],
          padding: ['0', '0 2rem 0 0'],
        }}
      >
        {links.map(({ name, slug }) => {
          return (
            <Text key={slug} sx={{ marginBottom: '0.5rem' }}>
              <Link to={`/${slug}/`}>{name}</Link>
            </Text>
          )
        })}
      </Box>

      <Box
        sx={{
          flex: ['45%', '25%', '33%'],
          lineHeight: ['25px', 'inherit'],
          marginTop: ['2rem', 0],
          minWidth: 'fit-content',
          padding: ['0', '0 2rem 0 0'],
        }}
      >
        <Text
          variant="link"
          sx={{
            marginBottom: '0.5rem',
            fontFamily: '"GT Walsheim Bold", Helvetica, Arial, sans-serif',
            textTransform: 'capitalize'
          }}
        >
          {translate('footer.contact.title')}
        </Text>
        <Text sx={{ marginBottom: '0.5rem' }}>
          <a href="mailto:hello@myhiki.com">hello@myhiki.com</a>
        </Text>
        <Text sx={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
          {translate('footer.contact.press_inquiries')}
        </Text>
        <Text>
          <a href="mailto:press@myhiki.com">press@myhiki.com</a>
        </Text>
      </Box>

      <Box sx={{
        display: ['flex', 'none', 'none'],
        flex: '45%',
        marginTop: '2rem',
      }}>
        <FooterSocial />
      </Box>
    </Flex>
  )
}

FooterLinks.propTypes = {
  catalog: PropTypes.shape({
    collections: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        slug: PropTypes.string
      })
    )
  }),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string
    })
  )
}

export default FooterLinks
