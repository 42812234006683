/** @jsx jsx */
import { Link } from 'gatsby'
import { jsx } from 'theme-ui'
import Logo from '~/assets/images/logos/logo-large.svg'
import LogoSimplified from '~/assets/images/logos/logo-small.svg'
import PropTypes from 'prop-types'

const NavMenuDesktopLogo = ({ atScrollTop, fillColor }) => {
  return (
    <Link
      to="/"
      sx={{
        flexShrink: 0,
        padding: '0 2rem',
        transition: 'all 0.2s ease-in-out 0s',
        '& svg': { height: '4rem', width: 'auto' },
        '&:hover': {
          transform: 'scale(1.05)',
        }
      }}
      aria-label="Home"
    >
      {atScrollTop ? (
        <Logo sx={{ marginTop: '-16px' }}></Logo>
      ) : (
        <LogoSimplified sx={{
          'g': { fill: fillColor }
        }} />
      )}
    </Link>
  )
}

NavMenuDesktopLogo.propTypes = {
  fillColor: PropTypes.string,
}

export default NavMenuDesktopLogo
