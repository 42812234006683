/** @jsx jsx */
import { Box, Flex, jsx } from 'theme-ui'
import useNotificationBar from '~/hooks/components/use-notification-bar'

const NotificationBar = () => {
  const { loaded, notification } = useNotificationBar()

  if (!notification) return null

  return (
    <Flex
      sx={{
        alignItems: 'center',
        backgroundColor: 'secondary',
        textAlign: 'center',
        justifyContent: 'space-around',
        variant: 'text.link'
      }}
    >
      <Box
        sx={{
          color: '#005D5F',
          letterSpacing: 'normal',
          opacity: loaded ? '1' : '0',
          'p': { margin: '0.5rem', textTransform: 'none', fontSize: '14px' }
        }}
        dangerouslySetInnerHTML={{
          __html: notification.description.childMarkdownRemark.html
        }}
      />
    </Flex>
  )
}

export default NotificationBar
