/** @jsx jsx */
import { Link } from 'gatsby'
import { jsx } from 'theme-ui'
import LogoSimplified from '~/assets/images/logos/logo-small.svg'
import PropTypes from 'prop-types'

const NavMenuLogo = ({fillColor}) => {
  return (
    <Link
      to="/"
      sx={{
        flexShrink: 0,
        '& svg': {
          marginTop: '0.4rem',
          transform: ['scale(0.5)']
        }
      }}
      aria-label="Home"
    >
      <LogoSimplified sx={{
          'g': { fill: fillColor }
        }} />
    </Link>
  )
}

NavMenuLogo.propTypes = {
  fillColor: PropTypes.string,
}

export default NavMenuLogo
