/** @jsx jsx */
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { Box, jsx } from 'theme-ui'

const NavMenuDesktopLink = ({ page }) => {
  return (
    <Box sx={{ textAlign: 'center', width: '200px' }}>
      <Link
        key={page.id}
        to={`/${page.slug}/`}
        sx={{
          color: 'inherit',
          variant: 'text.navLink',
          '&:hover': {
            opacity: 0.7,
          }
        }}
      >
        {page.name}
      </Link>
    </Box>
  )
}

NavMenuDesktopLink.propTypes = {
  page: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired
  })
}

export default NavMenuDesktopLink
