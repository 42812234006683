/** @jsx jsx */
import PropTypes from 'prop-types'
import { Box, jsx } from 'theme-ui'
import NavMenu from '~/components/Nav/Menu'
import NavMenuDesktop from '~/components/Nav/MenuDesktop'

export const NavBar = ({ pages, itemCount, ...props }) => {
  const backgroundColor = props.backgroundColor || '#fff'
  const logoColor = props.logoColor || '#fff'

  return (
    <Box
      as="nav"
      role="navigation"
      sx={{
        backgroundColor: backgroundColor,
        overflow: 'visible',
        textTransform: 'uppercase',
        width: '100%'
      }}
    >
      <NavMenuDesktop pages={pages} itemCount={itemCount} logoColor={logoColor} {...props} />
      <NavMenu pages={pages} itemCount={itemCount} {...props} />
    </Box>
  )
}

NavBar.propTypes = {
  backgroundColor: PropTypes.string,
  itemCount: PropTypes.number,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired
    })
  ),
  logoColor: PropTypes.string,
}

export default NavBar
