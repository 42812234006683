/** @jsx jsx */
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Button, Flex, Heading, jsx, Text } from 'theme-ui'
import FormInputWithLabel from '~/components/Generic/Form/InputWithLabel'
import { analytics, logSentryError, useTranslate } from '@arfabrands/gatsby-theme-assembly'
import { HikiLogo } from '~/components/HikiLogo'
import ArrowRight from '../../../assets/images/buttons/arrow-right.svg'

const FooterNewsletterSignup = () => {
  const translate = useTranslate()
  const { trackEmailCaptured } = analytics
  const { register, handleSubmit, errors } = useForm()
  const [submitted, setSubmitted] = useState(false)

  const onSubmit = async data => {
    const { email } = data

    const payload = {
      email: email,
      placement: {
        page: 'all-pages',
        component: 'footer',
        website: window.location.hostname
      }
    }

    try {
      trackEmailCaptured(payload)
      setSubmitted(true)
    } catch (err) {
      logSentryError(err, { source: 'FooterNewsletterSignup' })
    }
  }

  return (
    <Box
      sx={{
        borderBottom: ['1px solid', 'none'],
        marginBottom: ['1.5rem', null],
        paddingBottom: ['1rem'],
        textAlign: ['center', 'left'],
        width: ['100%', null, '45%']
      }}
    >
      <HikiLogo sx={{
          height: '5rem',
          marginBottom: '1.5rem',
        }}
      />

      <Box sx={{ width: ['100%', null, '70%'] }}>
        <Heading
          sx={{
            fontSize: '34px',
            marginBottom: ['1.5rem'],
          }}
        >
          {translate('footer.newsletter_signup.title')}
        </Heading>
        {/* <Text
          sx={{
            marginBottom: ['16px', null, '24px'],
            marginRight: 'auto',
            marginLeft: 'auto',
            maxWidth: [null, '60%', 'none']
          }}
        >
          {translate('footer.newsletter_signup.text')}
        </Text> */}

        {!submitted && (
          <Flex
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              flexDirection: ['column', null, 'row'],
              flexWrap: 'wrap',
              position: 'relative',
              width: [null, '350px']
            }}
          >
            <Box
              sx={{
                position: 'relative',
                flexGrow: 1,
              }}
            >
              <FormInputWithLabel
                name="email"
                title={translate('footer.newsletter_signup.input_label')}
                label={translate('footer.newsletter_signup.input_label')}
                placeholder={translate('footer.newsletter_signup.input_label')}
                aria-label={translate('footer.newsletter_signup.input_label')}
                ref={register({
                  required: true,
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: translate('validation.invalid_email')
                  }
                })}
                sx={{
                  padding: ['10px', '10px', '8px'],
                  maxWidth: '450px'
                }}
              />
            </Box>
            {errors && errors['email'] && (
              <Text
                variant="formError"
                sx={{
                  backgroundColor: 'errorLight',
                  padding: '12px 12px',
                  order: [null, null, 3],
                  marginBottom: ['0.75rem', null, '0']
                }}
              >
                {errors['email'].type === 'required' &&
                  translate('validation.required_field')}

                {errors['email'].type === 'pattern' &&
                  translate('validation.invalid_email')}
              </Text>
            )}
            <Button type="submit" sx={{
              backgroundColor: 'white85',
              color: 'brighTeal',
              flexShrink: 0,
              padding: '0.75rem 1rem',
              position: 'absolute',
              right: 0,
              top: '1px',
              lineHeight: 0,
            }}>
              <ArrowRight />
            </Button>
          </Flex>
        )}

        {submitted && (
          <Box
            sx={{
              margin: [null, 'auto'],
              maxWidth: [null, '350px', 'none'],
              textAlign: ['center', null, 'left']
            }}
          >
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default FooterNewsletterSignup
