/** @jsx jsx */
import { Box, Container, Flex, jsx } from 'theme-ui'
import FooterLegal from '~/components/Footer/Legal'
import FooterLinks from '~/components/Footer/Links'
import FooterNewsletterSignup from '~/components/Footer/NewsletterSignup'
import FooterSocial from '~/components/Footer/Social'
import useFooterQuery from '~/hooks/graphql/queries/use-footer'
import FooterBackground from '!file-loader!../../assets/images/backgrounds/hiki-teal.svg'
//import FooterMade from '~/components/Footer/Made'

export const Footer = () => {
  const { catalog, links, legal } = useFooterQuery()
  return (
    <Box
      as="footer"
      sx={{
        backgroundColor: '#23A1B0',
        backgroundImage: `url(${FooterBackground})`,
        backgroundSize: 'cover',
        color: '#FFF',
        padding: ['2.5rem 0 0 0', null, '2.5rem 0 0'],
      }}
    >
      <Container sx={{
        padding: ['0 1rem', null, '0 1.5rem 2rem'],
        margin: '0 auto',
        maxWidth: 'calc(1200px + 3rem)',
      }}>
        <Flex
          sx={{
            flexDirection: ['column', 'row']
          }}
        >
          <FooterNewsletterSignup />
          <FooterLinks links={links.pages} catalog={catalog} />
        </Flex>
        <Flex
          sx={{
            display: ['none', 'block', 'block'],
            justifyContent: 'space-between',
            flexDirection: ['column', null, 'row'],
          }}
        >
          <FooterSocial />
        </Flex>
        <Flex
          sx={{
            justifyContent: 'space-between',
            flexDirection: ['column', null, 'row'],
            marginTop: ['1.5rem', null, '1.5rem'],
            paddingBottom: '1rem'
          }}
        >
          <FooterLegal links={legal.pages} />
        </Flex>
      </Container>
    </Box>
  )
}

export default Footer
