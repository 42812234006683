/** @jsx jsx */
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { Flex, Text, jsx } from 'theme-ui'
const FooterLegal = ({ links }) => {
  return (
    <Flex
      sx={{
        borderTop: ['1px solid', 'none'],
        fontSize: 14,
        lineHeight: ['25px', 'inherit'],
        paddingTop: ['1.5rem', 0],
        textAlign: ['left', 'left'],
        width: '100%',
      }}
    >
      <Flex sx={{
        flexDirection: 'row',
        width: '100%',
      }}
      >
        {links.map(({ name, slug }) => {
          return (
            <Text key={slug} sx={{
              margin: ['0 .5rem 1rem 0', '0 1.25rem 0 0']
            }}>
              <Link to={`/${slug}/`}>{name}</Link>
            </Text>
          )
        })}
        <Text sx={{ margin: ['0 0 1rem 0', '0 0 0 0.75rem'] }}>
          &copy; {new Date().getFullYear()} HIKI
        </Text>
      </Flex>
    </Flex>
  )
}

FooterLegal.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string
    })
  )
}

export default FooterLegal
