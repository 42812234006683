/** @jsx jsx */
import React from 'react'
import { Box, Input, jsx } from 'theme-ui'

const FormInputWithLabel = React.forwardRef(
  ({ name, label, ...props }, ref) => {
    return (
      <Box sx={{ position: 'relative', height: '100%', width: '100%' }}>
        <Input
          sx={{
            backgroundColor: 'white20',
            borderRadius: 0,
            fontSize: ['13px', null, '16px'],
            letterSpacing: '1px',
            width: '100%',
            marginBottom: ['0.75rem', null, '0'],
            outline: 0,
            paddingLeft: [null, null, '1rem'],
            paddingRight: '4rem',
            variant: 'text.body',
            '::placeholder': {
              variant: 'text.link'
            },
          }}
          name={name}
          ref={ref}
          placeholder={label}
          {...props}
        />
      </Box>
    )
  }
)
export default FormInputWithLabel
