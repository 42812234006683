/** @jsx jsx */
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { jsx } from 'theme-ui'

const NavMenuLink = ({ page }) => {
  return (
    <Link
      key={page.id}
      to={`/${page.slug}/`}
      sx={{
        variant: 'text.navLink',
        color: 'inherit',
        lineHeight: '3rem',
        width: ['100%', 'auto'],
        textAlign: ['center', null, null],
        padding: ['0 1rem', '0'],
        '&:hover': {
          opacity: 0.7,
        }
      }}
    >
      {page.name}
    </Link>
  )
}

NavMenuLink.propTypes = {
  page: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired
  })
}

export default NavMenuLink
