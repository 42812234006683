/** @jsx jsx */
import { useState } from 'react'
import PropTypes from 'prop-types'
import { Flex, jsx, MenuButton } from 'theme-ui'
import NavMenuLogo from './Logo'
import NavMenuCartLink from './CartLink'
import NavMenuLinks from './Links'
import NavMenuLink from './Link'
import NavMenuControls from './Controls'

const NavMenu = ({ pages, itemCount, backgroundColor, navTextColor, logoColor }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Flex
      sx={{
        alignItems: 'center',
        color: 'inherit',
        display: [null, null, 'none'],
        justifyContent: 'space-between',
        padding: ['0 1rem', '0 1.5rem', '0'],
        width: '100%',
        height: '4rem'
      }}
    >
      <MenuButton
        onClick={() => setIsOpen(!isOpen)}
        sx={{
          color: navTextColor,
          display: [null, 'none', 'none'],
        }}
      />
      <NavMenuLogo fillColor={logoColor} />
      <NavMenuLinks isOpen={isOpen} backgroundColor={backgroundColor} navTextColor={navTextColor}>
        {pages.map(page => (
          <NavMenuLink page={page} key={page.id} />
        ))}
      </NavMenuLinks>
      <NavMenuControls>
        <NavMenuCartLink itemCount={itemCount} navTextColor={navTextColor} />
      </NavMenuControls>
    </Flex>
  )
}

NavMenu.propTypes = {
  pages: PropTypes.array,
  itemCount: PropTypes.number,
  showItemCount: PropTypes.bool
}

export default NavMenu
