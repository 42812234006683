import { useState, useEffect } from 'react'
import { utils } from '@arfabrands/gatsby-theme-assembly'
import useNotificationBarQuery from '~/hooks/graphql/queries/use-notification-bar'

const useNotificationBar = () => {
  const { getParam } = utils
  const [showRefNotification, setShowRefNotification] = useState(false)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
    setShowRefNotification(!!getParam('ref'))
  }, [setLoaded, setShowRefNotification, getParam])

  const { notificationBar, referralNotification } = useNotificationBarQuery()

  const notification = showRefNotification
    ? referralNotification
    : notificationBar && notificationBar.notification

  return { loaded, notification }
}

export default useNotificationBar
