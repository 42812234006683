/** @jsx jsx */
import { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { Box, jsx } from 'theme-ui'
import ShoppingCartIcon from '~/assets/images/icons/shopping-cart.svg'

const NavMenuCartLink = ({ children, itemCount, navTextColor }) => {
  const [showItemCount, setShowItemCount] = useState(false)

  const shoppingCartColor = navTextColor
  if (navTextColor === '#91D3CA') {
    navTextColor = '#005D5F'
  }

  useEffect(() => {
    setShowItemCount(typeof itemCount === 'number')
  }, [itemCount])

  return (
    <Link
      key="cart"
      to="/cart/"
      sx={{
        flexShrink: 0,
        variant: 'text.navLink',
        //opacity: showItemCount ? 1 : 0,
        lineHeight: '26px',
        paddingLeft: '2px',
        position: 'relative',
        textAlign: 'center',
        zIndex: 3,
      }}
    >
      <ShoppingCartIcon sx={{
          position: 'relative',
          left: '-1rem',
          top: '0.5rem',
          '& g': {
            stroke: shoppingCartColor,
          }
        }}
      />

      <Box sx={{
        background: 'white',
        width: '1.5rem',
        height: '1.5rem',
        borderRadius: '100%',
        color: navTextColor,
        fontSize: '14px',
        position: 'absolute',
        right: '-0.15rem',
        top: '-0.5rem',
      }}>{itemCount || 0}</Box>
      {children}
    </Link>
  )
}

NavMenuCartLink.propTypes = {
  itemCount: PropTypes.number,
  navTextColor: PropTypes.string,
}

export default NavMenuCartLink
