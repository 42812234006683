import PropTypes from 'prop-types'
import React from 'react'
import Nav from '~/components/Nav'
import Footer from '~/components/Footer'
import NotificationBar from '~/components/Notification/Bar'
import { Box } from 'theme-ui'

const Layout = props => {
  const { backgroundColor, children } = props

  return (
    <>
      <NotificationBar />
      <Box sx={{backgroundColor: backgroundColor}}>
        <Nav {...props} />
        {children}
      </Box>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default Layout
