/** @jsx jsx */
import PropTypes from 'prop-types'
import { Flex, jsx } from 'theme-ui'

const NavMenuLinks = ({ children, isOpen, backgroundColor, navTextColor }) => {
  return (
    <Flex
      sx={{
        alignItems: 'center',
        backgroundColor: backgroundColor,
        color: navTextColor,
        justifyContent: ['end', 'space-around'],
        padding: ['0', '0 2rem'],
        width: '100%',
        height: ['auto', '100%'],
        position: ['absolute', 'relative'],
        top: ['6rem', 'auto'],
        flexDirection: ['column', 'row'],
        left: [0, 'auto'],
        display: [isOpen ? 'flex' : 'none', 'flex'],
        zIndex: 1
      }}
    >
      {children}
    </Flex>
  )
}

NavMenuLinks.propTypes = {
  isOpen: PropTypes.bool,
  navTextColor: PropTypes.string,
}

export default NavMenuLinks
