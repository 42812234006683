import PropTypes from 'prop-types'
import React from 'react'

const HikiLogo = ({ className }) => {
  return (
    <svg
      viewBox="0 0 140 58"
      preserveAspectRatio="xMidYMid"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#FFF" fillRule="evenodd">
        <path d="M25.358.511V18.05c0 .283-.17.511-.38.511H18.02c-.21 0-.38-.228-.38-.51V.51c0-.282-.17-.511-.379-.511H.38C.17 0 0 .229 0 .511v56.977c0 .282.17.512.38.512H17.26c.21 0 .38-.23.38-.512V39.486c0-.282.17-.51.38-.51h6.958c.21 0 .38.228.38.51v18.002c0 .282.17.512.379.512h16.881c.21 0 .381-.23.381-.512V.51C43 .23 42.83 0 42.62 0H25.737c-.21 0-.38.229-.38.511M49.39 58h17.22c.214 0 .39-.229.39-.511V.51C67 .23 66.824 0 66.61 0H49.39c-.214 0-.39.229-.39.511V57.49c0 .282.176.511.39.511M97.292.29l-6.47 18.63c-.061.176-.192.29-.334.29h-.001c-.205 0-.371-.23-.371-.51V.51c0-.282-.167-.511-.371-.511H73.37C73.166 0 73 .229 73 .511v56.977c0 .282.166.512.371.512h16.374c.204 0 .37-.23.37-.512v-18c0-.283.167-.512.372-.512.143 0 .274.114.336.293l6.335 18.438c.061.178.192.293.336.293h19.134c.286 0 .465-.427.321-.768l-12.102-28.855a.674.674 0 01.003-.517L116.599.773c.148-.34-.03-.773-.318-.773H97.627c-.144 0-.273.113-.335.29M122.39 58h17.22c.216 0 .39-.229.39-.512V.51c0-.282-.174-.511-.39-.511h-17.22c-.214 0-.39.229-.39.511v56.977c0 .283.176.512.39.512" />
      </g>
    </svg>
  )
}

HikiLogo.propTypes = {
  className: PropTypes.string
}

export { HikiLogo }
